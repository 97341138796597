import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Title from "../../components/title"
import SocialIcons from "../../components/social-icons"
import cn from "classnames"
import * as styles from "./contact.module.scss"

export default function ContactPage() {
  return (
    <>
      <Seo
        title="Contact us - Let's make something together"
        description="Tell us about your project, get a quick estimate and a plan for making it live."
      />
      <Layout showHeaderCtaButton>
        <section className={styles.contact}>
          <header className={cn(styles.content, styles.header)}>
            <Title headingLevel="h1">Contact</Title>
            <Title headingLevel="p">
              Tell us about your project, get a quick estimate and a plan for
              making it live.
            </Title>
          </header>
          <div className={styles.content}>
            <div>
              <ul className={styles.emails}>
                <li>
                  <Title headingLevel="h4">Work with us</Title>
                  <svg width="24" height="20">
                    <g
                      fill="none"
                      stroke="#3C4856"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    >
                      <path d="M1 11v6a2 2 0 002 2h18a2 2 0 002-2v-6"></path>
                      <path d="M23 6V3a2 2 0 00-2-2H3a2 2 0 00-2 2v3l11 6 11-6z"></path>
                    </g>
                  </svg>
                  <a href="mailto:work@grycode.co.uk">work@grycode.co.uk</a>
                </li>
                <li>
                  <Title headingLevel="h4">Have random question?</Title>
                  <svg width="24" height="20">
                    <g fill="none" stroke="#3C4856">
                      <path d="M1 11v6a2 2 0 002 2h18a2 2 0 002-2v-6"></path>
                      <path d="M23 6V3a2 2 0 00-2-2H3a2 2 0 00-2 2v3l11 6 11-6z"></path>
                    </g>
                  </svg>
                  <a href="mailto:ask@grycode.co.uk">hello@grycode.co.uk</a>
                </li>
              </ul>
              <SocialIcons className={styles.socialIcons} />
            </div>
            <address className={styles.address}>
              <Title headingLevel="h4">London</Title>
              GRYCODE LTD
              <br />
              Company number 12264904
              <br />
              <br />
              <a
                href="https://g.page/grycode?share"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kemp House,
                <br />
                152-160 City Road
                <br />
                EC1V 2NX, London, UK
              </a>
            </address>
          </div>
        </section>
      </Layout>
    </>
  )
}
